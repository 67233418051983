.scrollx .ant-table-content {
    overflow-x: scroll !important;
}
.no-border-right-header .ant-table-thead .ant-table-cell::before {
    display: none;
}
.tablelevel1 .ant-table-thead > tr > th {
    background-color: #bdcdd6;
}
.tablelevel2 .ant-table-thead > tr > th {
    background-color: #ebf2fc;
}
.tablelevel3 .ant-table-thead > tr > th {
    background-color: #fcf4eb;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #93bfcf;
}
.ant-collapse,
.ant-card-head,
.ant-table-thead > tr > th {
    background-color: #bdcdd6;
}
.ant-layout-sider,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: #6096b4;
}
.ant-card-head-title,
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: bold;
}
